<template>
  <nav v-if="data" class="mobile block lg:hidden p-0" :class="[isOpen ? 'h-screen' : 'h-auto']">
    <div class="bg-primary-white shadow-md -mx-gap flex flex-col place-content-stretch max-h-screen relative">
      <div class="flex place-content-between px-gap-lg py-2 bg-primary-white z-50" ref="navIconBar">
        <a :href="homeUrl" :aria-label="translations['ARIA.HOME']">
          <img
              :src="`/assets/images/logos/secondary/logo-${data.entry.language}.svg`"
              class="nav-mobile-logo h-12 w-auto"
              alt="Logo Leukerbad"
          />
        </a>
        <div class="flex items-center space-x-8">
          <a :href="data.search.url" class="search-icon" :aria-label="translations['ARIA.SEARCH']"></a>
          <div @click="toggleMenu" class="hamburger-icon" :class="{ 'close-icon': isOpen }"></div>
        </div>
      </div>
      <div class="direct-access-link-container-mobile" :class="{ hide: directAccessHidden }" ref="quickAccessBar">
        <div
            class="direct-access-link"
            @click="toggleDirectAccess(DIRECT_ACCESS.MOUNTAIN_RAILWAYS)"
            :class="{ active: isDirectAccess(DIRECT_ACCESS.MOUNTAIN_RAILWAYS) }"
        >
          <i :class="isDirectAccess(DIRECT_ACCESS.MOUNTAIN_RAILWAYS) ? 'icon-close-small' : 'icon-cablecar'"></i>
        </div>
        <div
            class="direct-access-link"
            @click="toggleDirectAccess(DIRECT_ACCESS.WEBCAMS)"
            :class="{ active: isDirectAccess(DIRECT_ACCESS.WEBCAMS) }"
        >
          <i :class="isDirectAccess(DIRECT_ACCESS.WEBCAMS) ? 'icon-close-small' : 'icon-webcam'"></i>
        </div>
        <div
            class="direct-access-link"
            @click="toggleDirectAccess(DIRECT_ACCESS.INFO_CORNER)"
            :class="{ active: isDirectAccess(DIRECT_ACCESS.INFO_CORNER) }"
        >
          <i :class="isDirectAccess(DIRECT_ACCESS.INFO_CORNER) ? 'icon-close-small' : 'icon-weather'"></i>
        </div>
        <div
            class="direct-access-link"
            @click="toggleDirectAccess(DIRECT_ACCESS.MAPS)"
            :class="{ active: isDirectAccess(DIRECT_ACCESS.MAPS) }"
        >
          <i :class="isDirectAccess(DIRECT_ACCESS.MAPS) ? 'icon-close-small' : 'icon-maps'"></i>
        </div>
        <div
            class="direct-access-link relative"
            @click="toggleDirectAccess(DIRECT_ACCESS.NEWS)"
            :class="{ active: isDirectAccess(DIRECT_ACCESS.NEWS) }"
        >
          <i :class="isDirectAccess(DIRECT_ACCESS.NEWS) ? 'icon-close-small' : 'icon-news'"></i>
          <div class="counter" :class="filteredIssues.length > 0 ? '' : 'hidden'">
            {{ filteredIssues.length }}
          </div>
        </div>
      </div>
      <div class="direct-access-container mobile" :style="{ maxHeight: containerHeight }"
           :class="{ hidden: directAccessHidden }">
        <MountainRailways
            v-if="isDirectAccess(DIRECT_ACCESS.MOUNTAIN_RAILWAYS)"
            :railways="railways"
            :translations="translations"
            :railwaysLinkUrl="data.railwaysLinkUrl"
            :railwaysLastUpdated="data.railwaysLastUpdated"
        />
        <Webcams v-if="isDirectAccess(DIRECT_ACCESS.WEBCAMS)" :webcams="data.navigation.webcams"
                 :translations="translations"/>
        <InfoCorner
            v-if="isDirectAccess(DIRECT_ACCESS.INFO_CORNER)"
            :forecasts="data.navigation.forecasts"
            :snowData="data.navigation.snowData"
            :language="data.entry.language"
            :translations="translations"
        />
        <Maps
            v-if="isDirectAccess(DIRECT_ACCESS.MAPS)"
            :maps="data.navigation.maps"
            :mapLinks="data.navigation.mapLinks"
            :mapLinksTitle="data.navigation.mapLinksTitle"
            :winterMapUrl="data.navigation.winterMapUrl"
            :summerMapUrl="data.navigation.summerMapUrl"
          :language="data.entry.language"
            :translations="translations"
        />
        <News
            v-if="isDirectAccess(DIRECT_ACCESS.NEWS)"
            :translations="translations"
            :issuesTitle="data.navigation.issuesTitle"
            :issues="filteredIssues"
            :guestInfosTitle="data.navigation.guestInfosTitle"
            :guestInfo="data.navigation.guestInfo"
            :notificationLinks="data.navigation.notificationLinks"
            :notificationsLinksTitle="data.navigation.notificationsLinksTitle"
        />
      </div>

      <div v-if="currentMainNavItem && currentMainNavItem.mainPage[0].sectionHandle === 'shop'" class="tabs relative"
           :class="{ hidden: activeDirectAccess !== '' }" ref="shopTabsContainer">
        <div class="tabs-scroll-indicator cursor-pointer left icon-arrow-small"
             :class="showTabBarScrollIndicatorLeft ? '' : 'hidden'"
        ></div>
        <div class="tabs-scroll-indicator cursor-pointer right icon-arrow-small"
             :class="showTabBarScrollIndicatorRight ? '' : 'hidden'"
        ></div>
        <div class="activities-tabs overflow-x-auto flex border-b border-tertiary-gray p-0" ref="shopTabsBar" @scroll="tabBarScrollIndicators">
          <a v-for="item in currentMainNavItem.subpages" :href="item.url" :key="item.id" :data-mobile-entry-id="item.id"
             class="cursor-pointer flex-shrink-0 px-6 py-4 text-primary-black"
             :class="[isSubNavItemActive(item) ? 'active' : '']">
            {{ item.title }}
          </a>
        </div>
      </div>
      <div
          class="overflow-y-scroll px-gap-lg py-gap border-t border-tertiary-gray"
          :class="{ hidden: !(isOpen && isActiveMenu(MENU.NAVIGATION)) }"
      >
        <div class="w-full">
          <ul class="nav-links-main">
            <li
                v-for="item in data.navigation.items"
                :key="item.id"
                @click="toggleSubmenu(item)"
                class="nav-mobile-main-page"
                :class="[selectedMainNavItem === item ? 'active ' : '']"
                :id="item.id"
            >
              <span :class="[isMainNavItemActive(item) ? 'active-site' : '']">
                {{ item.mainPage[0].title }}
              </span>
              <div
                  v-if="selectedMainNavItem"
                  class="place-content-between pl-gap"
                  :key="item.id"
                  :class="[selectedMainNavItem === item ? 'flex' : 'hidden']"
              >
                <ul class="nav-links-sub w-full">
                  <li v-if="item.mainPage[0].sectionHandle !== 'shop'" :class="[isHubPage(item) ? 'active-site' : '']">
                    <a :href="selectedMainNavItem.mainPage[0].url" class="subheader">{{ translations.MENU_OVERVIEW }}</a>
                  </li>
                  <li v-for="item in selectedMainNavItem.subpages" :key="item.id">
                    <a :href="item.url" :class="[isSubNavItemActive(item) ? 'active-site' : '']" class="subheader">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="overflow-y-scroll pb-24" :class="{ hidden: !isOpen }">
        <div
            class="nav-mobile-back-container"
            :class="[isActiveMenu(MENU.NAVIGATION) ? 'hidden' : 'flex']"
            @click="setActiveMenu(MENU.NAVIGATION)"
        >
          <div class="nav-mobile-back">
            {{ translations.MENU_BACK }}
          </div>
        </div>
        <div class="px-gap-lg" :class="{ hidden: !isActiveMenu(MENU.LANGUAGE) }">
          <div class="w-full">
            <p class="text-xl font-bold pt-4 pb-2">{{ translations.LANGUAGE }}</p>
            <LanguageSwitcher :language-switcher="languageSwitcher" :translations="translations"/>
          </div>
        </div>
      </div>
      <div class="absolute bg-primary-white bottom-0 w-full flex place-content-stretch" :class="{ hidden: !isOpen }">
        <div class="w-full py-4 border-t border-r border-tertiary-gray" @click="setActiveMenu(MENU.LANGUAGE)">
          <div class="px-gap-lg text-sm">{{ translations.LANGUAGE }}</div>
        </div>
        <a :href="data.navigation.contactLinkMobileMenu[0].url" class="w-full py-4 border-t border-tertiary-gray">
          <div class="px-gap-lg text-sm text-primary-black">{{ translations.CONTACT }}</div>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher';
import MountainRailways from '@/components/layout/direct-access/MountainRailways';
import Webcams from '@/components/layout/direct-access/Webcams';
import InfoCorner from '@/components/layout/direct-access/InfoCorner';
import News from '@/components/layout/direct-access/News';
import Maps from '@/components/layout/direct-access/Maps';
import infoSnowService from "../common/services/infoSnowService";

export default {
  name: 'Navigation',
  components: {MountainRailways, Maps, News, InfoCorner, Webcams, LanguageSwitcher},
  props: ['data', 'languageSwitcher', 'translations'],
  data: () => ({
    railways: [],
    currentScrollPosition: false,
    isScrollingDown: false,
    isOpen: false,
    MENU: {
      NAVIGATION: 'NAVIGATION',
      CONTACT: 'CONTACT',
      LANGUAGE: 'LANGUAGE'
    },
    DIRECT_ACCESS: {
      MOUNTAIN_RAILWAYS: 'MOUNTAIN_RAILWAYS',
      WEBCAMS: 'WEBCAMS',
      INFO_CORNER: 'INFO_CORNER',
      MAPS: 'MAPS',
      NEWS: 'NEWS'
    },
    activeMenu: 'NAVIGATION',
    activeDirectAccess: '',
    selectedMainNavItem: '',
    containerHeight: 0,
    showTabBarScrollIndicatorLeft: false,
    showTabBarScrollIndicatorRight: false
}),
  async created() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.currentMainNavItem?.mainPage[0].sectionHandle === 'shop') {
      window.addEventListener('hashchange', this.handleHashChange);
    }
    const railwaysResult = (await infoSnowService.getMountainRailwaysData(this.data.entry.language.slice(0, 2))).data;
    this.railways = infoSnowService.formatMountainRailways(this.data.navigation.railways, railwaysResult);
  },
  mounted() {
    this.directAccessContainerHeight();
    this.setCurrentActivePage();
    this.tabBarScrollIndicators();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    if (this.currentMainNavItem?.mainPage[0].sectionHandle === 'shop') {
      window.removeEventListener('hashchange', this.handleHashChange);
    }
  },
  computed: {
    homeUrl() {
      return `/${this.data.entry.language === 'de-CH' ? '' : this.data.entry.language.substring(0, 2)}`;
    },
    headerImagePresent() {
      return document.getElementsByClassName('header-image-overlay').length > 0;
    },
    directAccessHidden() {
      return this.isScrollingDown && this.currentScrollPosition > 10 && !this.activeDirectAccess;
    },
    filteredIssues() {
      return this.data.navigation.issues ? this.data.navigation.issues.filter(issue => !issue.date || new Date(issue.date).toISOString() > new Date().toISOString()) : [];
    },
    currentMainNavItem() {
      return this.data.navigation.items.find(item => this.isMainNavItemActive(item));
    }
  },
  methods: {
    handleScroll() {
      this.isScrollingDown = window.scrollY > this.currentScrollPosition;
      this.currentScrollPosition = window.scrollY;
    },
    setCurrentActivePage() {
      for (const item of this.data.navigation.items) {
        const isMainNavItemActive =
            this.data.entry.id === item.mainPage[0].id ||
            item.subpages.some(subpage => subpage.id === this.data.entry.id) ||
            (this.data.entry.parent && item.subpages.some(subpage => subpage.id === this.data.entry.parent.id));

        if (isMainNavItemActive) {
          this.toggleSubmenu(item);
        }
      }
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
      this.activeDirectAccess = '';
      this.togglePageScrollFreeze();
    },
    setActiveMenu(menu) {
      this.activeMenu = menu;
    },
    isActiveMenu(menu) {
      return this.activeMenu === menu;
    },
    toggleSubmenu(item) {
      this.selectedMainNavItem = this.selectedMainNavItem === item ? '' : item;
    },
    toggleDirectAccess(page) {
      if (this.isDirectAccess(page)) {
        this.activeDirectAccess = '';
        this.togglePageScrollFreeze();
      } else {
        if (this.isOpen) {
          this.toggleMenu();
        }
        this.directAccessContainerHeight();
        this.activeDirectAccess = page;
        this.togglePageScrollFreeze();
      }
    },
    isDirectAccess(page) {
      return this.activeDirectAccess === page;
    },
    directAccessContainerHeight() {
      let iconBarHeight = this.$refs.navIconBar.clientHeight;
      let quickAccessBarHeight = this.$refs.quickAccessBar.clientHeight;
      this.containerHeight = `calc(${window.innerHeight}px - ${iconBarHeight.toString()}px - ${quickAccessBarHeight.toString()}px)`;
    },
    togglePageScrollFreeze() {
      this.activeDirectAccess === '' && !this.isOpen
          ? document.getElementsByTagName('body')[0].classList.remove('freeze-y')
          : document.getElementsByTagName('body')[0].classList.add('freeze-y');
    },
    isMainNavItemActive(item) {
      return (
          this.data.entry.id === item.mainPage[0].id ||
          item.subpages.some(subpage => subpage.id === this.data.entry.id) ||
          (this.data.entry.parent && item.subpages.some(subpage => subpage.id === this.data.entry.parent.id))
      );
    },
    isSubNavItemActive(item) {
      const itemId = this.data.entry.sectionHandle === 'shop' ? this.getItemIdFromHash() : this.data.entry.id;
      return itemId === item.id || (this.data.entry.parent && this.data.entry.parent.id === item.id);
    },
    isHubPage(item) {
      return this.currentMainNavItem && this.currentMainNavItem.mainPage[0].id === item.mainPage[0].id && item.mainPage[0].sectionHandle !== 'shop';
    },
    handleHashChange() {
      document.querySelectorAll('[data-mobile-entry-id]').forEach(item => item.classList.remove('active'));
      document.querySelector('[data-mobile-entry-id="' + this.getItemIdFromHash() + '"]').classList.add('active');
    },
    getItemIdFromHash() {
      let allSubpages = [];
      this.data.navigation.items.forEach(item => {
        allSubpages = allSubpages.concat(item.subpages);
      });
      let hash = window.location.href.split("/").pop();
      switch (hash) {
        case 'pois':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopPois').id;
        case 'erlebnisse':
        case 'experiences':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopExperiences').id;
        case 'unterkuenfte':
        case 'accommodations':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopAccommodations').id;
        case 'veranstaltungen':
        case 'events':
        case 'manifestations':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopEvents').id;
        case 'tickets':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopTickets').id;
        case 'topprodukte':
        case 'topproduits':
        case 'topproducts':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopTopproducts').id;
        case 'souvenir':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopSouvenir').id;
        default:
          return this.data.entry.id;
      }
    },
    tabBarScrollIndicators() {
      this.showTabBarScrollIndicatorRight = this.$refs.shopTabsBar?.scrollWidth - (this.$refs.shopTabsBar?.scrollLeft + this.$refs.shopTabsContainer?.clientWidth) > 15;
      this.showTabBarScrollIndicatorLeft = this.$refs.shopTabsBar?.scrollLeft > 15;
    }
  }
};
</script>
